<template>
  <v-container fluid style="width: 80%">
    <v-data-table
      dense
      :headers="headers"
      :items="filteredCasefiles"
      class="elevation-1"
      :loading="loadTable"
      loading-text="Working on it ......"
      style="cursor: pointer"
      @click:row="viewItem"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 30, 40, 50, 100],
      }"
      :items-per-page="50"
    >
      <template v-slot:[`header.id`]="{ header }">
        {{ header.text.toUpperCase() }}
      </template>
      <!-- <template slot="item.numbering" slot-scope="props">
        {{ props.index + 1 }}
      </template> -->
      <template slot="item.numbering" slot-scope="props">
        {{ props.index + 1 }}
      </template>
      <template v-slot:[`item.vehicleNo`]="{ item }">
        <span v-if="item.vehicleNo">
          <div v-if="item.vehicleNo">
            {{ item.vehicleNo.toUpperCase() }}
          </div>
        </span>
        <span v-else>TBA</span>
      </template>
      <template v-slot:[`item.dateOfAssign`]="{ item }">
        {{ formatDate(item.dateOfAssign) }}
      </template>
      <template v-slot:[`item.days`]="{ item }">
        {{ dateDiff(moment(item.dateOfAssign).format(), moment().format()) }}
        Day(s)
      </template>

      <template v-slot:[`item.adjuster`]="{ item }">
        <span v-if="item.adjuster">
          <div v-for="adjuster in filterUser(item.adjuster)" :key="adjuster.id">
            {{ adjuster.username }}
          </div>
        </span>
        <span v-else>TBA</span>
      </template>
      <template v-slot:[`item.fileStatus`]="{ item }">
        <v-tooltip left transition="fab-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="item.flag === 1"
              small
              color="green lighten-1"
              v-bind="attrs"
              v-on="on"
            >
              <!-- mdi-check-decagram-outline -->
              mdi-shield-check
            </v-icon>
            <v-icon
              v-if="item.flag === 2"
              small
              color="orange lighten-1"
              v-bind="attrs"
              v-on="on"
            >
              <!-- mdi-alarm-light-off-outline -->
              mdi-shield-half-full
            </v-icon>
            <v-icon
              v-if="item.flag === 3"
              small
              color="red darken-5"
              v-bind="attrs"
              v-on="on"
            >
              <!-- mdi-alarm-light-off-outline -->
              mdi-shield-alert-outline
            </v-icon>
          </template>
          <!-- <span> {{ item.flag ? "Genuine" : "Suspected Fraud" }}</span> -->
          <span v-if="item.flag === 1">GENUINE</span>
          <span v-if="item.flag === 2">SUSPECTED FRAUD</span>
          <span v-if="item.flag === 3">FRAUD</span>
        </v-tooltip>

        <span
          v-for="allStatus in filterStages(item.fileStatus)"
          :key="allStatus.id"
          v-bind="allStatus.item"
        >
          <v-chip label outlined block x-small>
            {{ allStatus.name }}
          </v-chip>
        </span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <tr class="font-weight-black d-flex">
          AA/
          <div v-for="dept in filterDept(item.refType)" :key="dept.id">
            {{ dept.name
            }}<span
              v-for="(subDept, index) in filterSubDept(item.subRefType)"
              :key="index"
              >/{{ subDept.subCode }}</span
            >
          </div>
          /{{
            item.id
          }}/{{
            yearOnly(item.createdAt)
          }}/
          <div
            v-for="(branch, index) in filterBranch(item.branch)"
            :key="index"
          >
            {{ branch.brCode }}
          </div>
        </tr>
      </template>

      <!-- <template v-slot:[`item.fileStatus`]="{ item }">
        <span
          v-for="allStatus in filterStages(item.fileStatus)"
          :key="allStatus.id"
        >
          {{ allStatus.name }}
        </span>
      </template> -->

      <template v-slot:[`item.insurer`]="{ item }">
        <div v-for="ins in filterIns(item.insurer)" :key="ins.id">
          <div class="text-truncate" style="max-width: 80px">
            {{ ins.name }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.dateOfLoss`]="{ item }">
        {{ formatDate(item.dateOfLoss) }}
      </template>
      <template v-slot:[`item.placeOfLoss`]="{ item }">
        <!-- {{ formatDate(item.dateOfLoss) }} -->
        <span v-if="item.placeOfLoss"> {{ item.placeOfLoss }}, </span>

        <span v-for="state in filterStates(item.stateOfLoss)" :key="state.id"
          >{{ state.name }}
        </span>
      </template>
      <!-- <template v-slot:[`item.id`]="{ item }">
        <tr
          class="font-weight-black"
          style="cursor: pointer"
          @click="viewItem(item.id)"
        >
          AA/{{
            item.refType
          }}/{{
            item.id
          }}/{{
            item.year
          }}/{{
            item.branch
          }}
        </tr>
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import CasefileDataService from "../../services/CasefileDataService";
import BranchDataService from "../../services/BranchDataService";
import StateDataService from "../../services/StateDataService";
import DeptDataService from "../../services/DeptDataService";
import SubRefTypeDataService from "../../services/SubDeptDataService";
import InsDataService from "../../services/InsDataService";
import StagesDataService from "../../services/StageDataService";
import MemberDataService from "../../services/MemberDataService";
import moment from "moment";

export default {
  name: "detail-by-ref",
  components: {},

  data() {
    return {
      loadTable: true,
      headers: [
        // {
        //   text: "File Reference",
        //   align: "start",
        //   value: "id",
        // },
        // { text: "Status", value: "fileStatus" },
        // { text: "Insurer", value: "insurer" },
        // { text: "Insurers Reference", value: "claimNo" },
        // { text: "Date Of Loss", value: "dateOfLoss" },
        // { text: "Place Of Loss", value: "placeOfLoss" },

        {
          text: "NO.",
          align: "start",
          sortable: false,
          value: "numbering",
        },
        {
          text: "VEHICLE NO.",
          align: "center",
          value: "vehicleNo",
          sortable: false,
        },
        {
          text: "AA REF",
          align: "start",
          value: "id",
          sortable: false,
        },

        {
          text: "INSURER",
          align: "start",
          value: "insurer",
          sortable: false,
        },
        {
          text: "DOA",
          align: "start",
          sortable: false,
          value: "dateOfAssign",
        },
        {
          text: "DAYS",
          align: "start",
          sortable: false,
          value: "days",
        },
        {
          text: "ADJUSTER",
          align: "start",
          sortable: false,
          value: "adjuster",
        },
        {
          text: "STATUS",
          align: "center",
          value: "fileStatus",
          sortable: false,
        },
      ],
      casefiles: [],
      departments: [],
      subRefTypes: [],
      branches: [],
      states: [],
      insurer: [],
      members: [],
      stages: [],
    };
  },
  mounted() {
    this.retrieveCasefiles(
      this.$route.params.fileStatus && this.$route.params.refType
    );
    this.retrieveDepts();
    this.retrieveSubRefType();
    this.retrieveBranches();
    this.retrieveStates();
    this.retrieveIns();
    this.retrieveMembers();
    this.retrieveStages();
  },

  computed: {
    filteredCasefiles() {
      if (
        this.$store.state.auth.user.roles.includes("MANAGER") ||
        this.$store.state.auth.user.roles.includes("ADMIN")
      ) {
        return this.casefiles.filter((casefile) => {
          return (
            casefile.fileStatus === this.$route.params.fileStatus &&
            casefile.refType == this.$route.params.refType
          );
        });
      } else if (
        this.$store.state.auth.user.roles.includes("BRANCHCLERK") ||
        this.$store.state.auth.user.roles.includes("BRANCHMANAGER")
      ) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            x.fileStatus === this.$route.params.fileStatus &&
            x.refType == this.$route.params.refType
        );
      } else if (this.$store.state.auth.user.roles.includes("ADJUSTER")) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            this.$store.state.auth.user.id.toString().includes(x.adjuster) &&
            x.fileStatus === this.$route.params.fileStatus &&
            x.refType == this.$route.params.refType
        );
      } else if (this.$store.state.auth.user.roles.includes("EDITOR")) {
        return this.casefiles.filter(
          (x) =>
            this.$store.state.auth.user.dept.includes(x.refType) &&
            this.$store.state.auth.user.branches.includes(x.branch) &&
            this.$store.state.auth.user.id.toString().includes(x.editor) &&
            x.fileStatus === this.$route.params.fileStatus &&
            x.refType == this.$route.params.refType
        );
      } else
        return this.casefiles.filter((casefile) => {
          return (
            casefile.fileStatus === this.$route.params.fileStatus &&
            casefile.refType == this.$route.params.refType
          );
        });
    },
  },

  methods: {
    moment: function (date) {
      return moment(date);
    },
    filterBranch(item) {
      return this.branches.filter((branch) => branch.id === item);
    },
    filterStates(item) {
      return this.states.filter((state) => state.id === item);
    },
    filterDept(item) {
      return this.departments.filter((dept) => dept.id === item);
    },
    filterSubDept(item) {
      return this.subRefTypes.filter((subRef) => subRef.id === item);
    },
    filterIns(item) {
      return this.insurer.filter((insurer) => insurer.id === item);
    },
    filterUser(item) {
      return this.members.filter((member) => member.id === item);
    },
    filterStages(item) {
      return this.stages.filter((stage) => stage.stageCode === item);
    },
    yearOnly(value) {
      // return moment(value).format("YYYY");
      return moment(value).format("YY");
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    dateDiff(startDateString, endDateString) {
      let start = moment(startDateString);
      let end = moment(endDateString);
      let duration = moment.duration(end.diff(start));
      return Math.round(duration.asDays());
    },
    retrieveCasefiles() {
      CasefileDataService.getAllStatusRefType(
        this.$route.params.fileStatus,
        this.$route.params.refType
      )
        .then((response) => {
          this.casefiles = response.data;
          this.loadTable = false;
        })
        .catch((e) => {
          console.log("Error fetching casefiles:", e);
          this.loadTable = false;
        });
    },
    retrieveDepts() {
      DeptDataService.getAll()
        .then((response) => {
          this.departments = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveSubRefType() {
      SubRefTypeDataService.getAll()
        .then((response) => {
          this.subRefTypes = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveBranches() {
      BranchDataService.getAll()
        .then((response) => {
          this.branches = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStates() {
      StateDataService.getAll()
        .then((response) => {
          this.states = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveIns() {
      InsDataService.getAll()
        .then((response) => {
          this.insurer = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveMembers() {
      MemberDataService.getAll()
        .then((response) => {
          this.members = response.data.filter((item) =>
            item.roles.some((b) => b.roleCode === "adjuster")
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStages() {
      StagesDataService.getAll()
        .then((response) => {
          this.stages = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    viewItem(id) {
      this.$router.push({
        name: "vuexFile",
        params: { id: id.id },
      });
    },
  },
};
</script>
